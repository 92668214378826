* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "TextFont";
}
@font-face {
  font-family: "BigFont";
  src: local("BigFont"), url(./fonts/BebasNeue-Regular.ttf) format("truetype");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  font-display: swap;
}
@font-face {
  font-family: "ItalicFont";
  src: local("ItalicFont"),
    url(./fonts/OpenSansHebrew-Italic.ttf) format("truetype");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  font-display: swap;
}
@font-face {
  font-family: "TextFont";
  src: local("TextFont"), url(./fonts/overpass-regular.otf) format("opentype");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  font-display: swap;
}
